<template>
  <div class="map_tip_line" :style="{ width: width + 'px', height: height + 'px' }">
    <svg :width="width + 'px'" :height="height + 'px'">
      <line x1="0" y1="0" :x2="width" :y2="height" style="stroke: #C18040; stroke-width:2"/>
    </svg>
  </div>
</template>
<script>
export default {
  props: {
    width: {
      type: Number,
      default: 2
    },
    height: {
      type: Number,
      default: 2
    }
  }
}
</script>
<style lang="scss" scoped>
.map_tip_line {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #fff;
}
</style>
